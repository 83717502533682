<template>
  <div
    class="v-slots-half-images"
    itemscope
    itemtype="http://schema.org/ImageObject"
  >
    <div class="v-slot-half-image-single">
      <template v-if="slotLeft">
        <arora-nuxt-image
          v-if="imageExists(slotLeft.CombineMobileImage)"
          :key="`CombineImage-${slotLeft.ID}`"
          :alt="slotLeft.Name"
          :image="slotLeft.CombineMobileImage"
          :scale="2"
          image-type="CombineMobile"
        />
        <arora-nuxt-image
          v-else-if="imageExists(slotLeft.NormalImage)"
          :key="`NormalImage-${slotLeft.ID}`"
          :alt="slotLeft.Name"
          :image="slotLeft.NormalImage"
          :scale="2"
          image-type="ProductNormal"
        />
      </template>
      <icon-old-menu-slot-half v-else />
    </div>
    <div class="v-slot-half-image-single">
      <template v-if="slotRight">
        <arora-nuxt-image
          v-if="imageExists(slotRight.CombineMobileImage)"
          :key="`CombineImage-${slotRight.ID}`"
          :alt="slotRight.Name"
          :image="slotRight.CombineMobileImage"
          :scale="2"
          image-type="CombineMobile"
        />
        <arora-nuxt-image
          v-else-if="imageExists(slotRight.NormalImage)"
          :key="`NormalImage-${slotRight.ID}`"
          :alt="slotRight.Name"
          :image="slotRight.NormalImage"
          :scale="2"
          image-type="ProductNormal"
        />
      </template>
      <icon-old-menu-slot-half v-else />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductInList } from '~types/menuStore'

defineProps<{
  slotLeft: ProductInList | null
  slotRight: ProductInList | null
}>()

const { imageExists } = useImageInfo()
</script>

<style lang="scss">
@use 'assets/variables';

.v-slots-half-images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  gap: 2px;
  grid-auto-flow: row;
  grid-template-areas: 'left-half right-half';
  overflow: hidden;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;

  .v-slot-half-image-single {
    aspect-ratio: 1/2;
    overflow: hidden;

    cursor: pointer;

    &:nth-child(1) {
      grid-area: left-half;

      .v-img-fluid {
        transform: translate(25%, 25%);
      }

      svg {
        transform: translate(25%, 25%);
        scale: 2;
        fill: variables.$BodyElementsBackground;
      }
    }

    &:nth-child(2) {
      grid-area: right-half;

      .v-img-fluid {
        transform: translate(-25%, 25%);
      }

      svg {
        transform: translate(-25%, 25%);
        scale: 2;
        fill: variables.$BodyElementsBackground;
      }
    }

    .v-img-fluid {
      object-fit: cover;
      width: auto;
    }
  }
}
</style>
